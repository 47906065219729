import gql from 'graphql-tag';

export const GET_USER = gql`
    query getUser($id: String!) {
        systemUser(id: $id) {
            stores {
                id
                name
            }
            subStores {
                id
                name
                status
                adminUserId
                createdAt
                updatedAt
                store {
                    id
                    name
                }
            }
        }
    }
`;
