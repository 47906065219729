import { Box, BoxProps } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useStore } from 'src/modules/Common/context/store-context';

import { SubStoreStatus } from '../../../generated/graphql';
import { BootstrapInput, MiniSelect, MiniSelectLabel } from '../../Common/Common/MiniSelect';
import { SubStoreContextType, SubStoreType, useSubStore } from '../../Common/context/sub-store-context';

export const useSubStoreMenu = (): {
    list: SubStoreType[],
    setCurrentId: SubStoreContextType['setSelectedSubStoreId'],
    current?: SubStoreType | undefined,
} => {
    const { subStores, selectedSubStoreId, setSelectedSubStoreId } = useSubStore();
    const [ storeId ] = useStore();

    const activeSubStores = subStores
        .filter((subStore) =>
            (subStore.status === SubStoreStatus.Active && subStore.store.id == storeId))
        .sort((a, b) =>
            (a.name || '').localeCompare(b.name || ''));

    return {
        list: activeSubStores,
        current: activeSubStores.find(subStore => subStore.id === selectedSubStoreId),
        setCurrentId: setSelectedSubStoreId,
    };
}

function SubStoreTopMenu({ ...props }: BoxProps) {
    const { list, current, setCurrentId } = useSubStoreMenu();

    const onSelect = (id: number) => {
        setCurrentId(id);
    };

    return (
        <Box {...props}>
            <MiniSelectLabel>Bokningslista:</MiniSelectLabel>&nbsp;
            <MiniSelect
                defaultValue={0}
                value={current?.id}
                onChange={(e) => onSelect(Number(e.target.value))}
                input={<BootstrapInput />}
            >
                <MenuItem
                    key={0}
                    value={0}
                >Huvudlista</MenuItem>
                {list.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
                {list.length === 0 && <MenuItem disabled>Inga aktiva listor</MenuItem>}
            </MiniSelect>
        </Box>
    );
}

export default SubStoreTopMenu;
