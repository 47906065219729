import './Menu.scss';

import {
    faCalendarAlt,
    faCalendarWeek,
    faCashRegister,
    faColumns,
    faHouseUser,
    faListUl,
    faSms,
    faStore,
    faUsers,
    faUsersCog,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltip from '@material-ui/core/Tooltip';
import { Auth } from 'aws-amplify';
import React from 'react';
import { NavLink } from 'react-router-dom';

import MenuDivider from './MenuDivider';

export default class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: '',
        };
    }

    hasAccessToGroup(name) {
        if (!this.state.user) {
            return false;
        }

        const groups = this.state.user.signInUserSession.accessToken.payload[
            'cognito:groups'
        ];
        return groups.indexOf(name) !== -1;
    }

    hasAccessToGroups(names) {
        return !!names.find(this.hasAccessToGroup.bind(this));
    }

    componentDidMount() {
        this.authPromise = Auth.currentAuthenticatedUser();

        this.authPromise.then((user) => {
            this.setState({ user: user });
        });
    }

    render() {

        return (
            <nav className={'menu'}>
                <ul>
                    <MenuItem
                        className={'dashboard'}
                        title={'Översikt'}
                        to={'/'}
                        icon={<FontAwesomeIcon icon={faColumns} size="2x" />}
                        exact={true}
                    />
                    <MenuItem
                        className={'orders'}
                        title={'Alla ordrar'}
                        to={'/orders'}
                        icon={<FontAwesomeIcon icon={faListUl} size="2x" />}
                    />
                    <MenuItem
                        className={'customers'}
                        title={'Kunder'}
                        to={'/customer'}
                        icon={<FontAwesomeIcon icon={faUsers} size="2x" />}
                    />
                    <MenuItem
                        className={'schedule'}
                        title={'Schema'}
                        to={'/schedule-overview'}
                        icon={<FontAwesomeIcon icon={faCalendarAlt} size="2x" />}
                    />
                    {this.hasAccessToGroups(['StoreAdmins', 'Admins']) && (
                        <>
                            <MenuDivider />
                            <MenuItem
                                className={'schedule-administration'}
                                title={'Schemainställningar'}
                                to={'/schedule-administration'}
                                icon={<FontAwesomeIcon icon={faCalendarWeek} size="2x" />}
                            />
                            <MenuItem
                                className={'user-store-administration'}
                                title={'Mekaniker'}
                                to={'/user-store-administration'}
                                icon={<FontAwesomeIcon icon={faHouseUser} size="2x" />}
                            />
                            <MenuItem
                                className={'heads'}
                                title={'Heads-integration'}
                                to={'/heads'}
                                icon={<FontAwesomeIcon icon={faCashRegister} size="2x" />}
                            />
                        </>
                    )}
                    {this.hasAccessToGroups(['Admins']) && (
                        <>
                            <MenuDivider />
                            <MenuItem
                                className={'sms-overview'}
                                title={'Skickade SMS'}
                                to={'/sms-overview'}
                                icon={<FontAwesomeIcon icon={faSms} size="2x" />}
                            />
                            <MenuItem
                                className={'user-administration'}
                                title={'Användare'}
                                to={'/user-administration'}
                                icon={<FontAwesomeIcon icon={faUsersCog} size="2x" />}
                            />
                            <MenuItem
                                className={'store-administration'}
                                title={'Butiksinställningar'}
                                to={'/store-administration'}
                                icon={<FontAwesomeIcon icon={faStore} size="2x" />}
                            />
                        </>
                    )}
                </ul>
            </nav>
        );
    }
}

function MenuItem(props) {
    const {
        className,
        title,
        icon,
        to,
        exact = false,
    } = props;

    return <li className={className}>
        <Tooltip
            title={title}
            placement="right"
        >
            <NavLink
                exact={exact}
                to={to}
                activeClassName="is-active"
                className="nav-link"
            >
                {icon}
            </NavLink>
        </Tooltip>
    </li>;
}
