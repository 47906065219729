import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@material-ui/core/Tooltip';
import { format } from 'date-fns';
import React from 'react';

import AppTableButton from '../../Common/Common/AppTableButton';
import { useDialog2 } from '../../Common/custom-hooks/useDialog2';
import DataList from '../../Common/DataList/DataList';
import AppButton from '../../Common/Form/AppButton';
import { GET_SCHEDULE_DEVIATIONS } from '../graphql/ScheduleDeviationGql';
import ScheduleDeviationDialog from './ScheduleDeviationDialog';

const CreateScheduleDeviationButton = ({ onChange }) => {
    const { createDialog } = useDialog2();
    const open = () => {
        const ref = createDialog((ref) => (
            <ScheduleDeviationDialog data={{}} dialogRef={ref} />
        ));
        ref.onClose.push((created) => {
            if (created) {
                onChange();
            }
        });
    };

    return (
        <AppButton onClick={open} icon={faPlus}>
            Lägg till
        </AppButton>
    );
};

const EditButton = ({ onChange, row }) => {
    const { createDialog } = useDialog2();
    const open = () => {
        const ref = createDialog((ref) => (
            <ScheduleDeviationDialog data={row} dialogRef={ref} />
        ));
        ref.onClose.push((changed) => {
            if (changed) {
                onChange();
            }
        });
    };

    return (
        <AppTableButton onClick={open} icon={faPen}>
            Lägg till
        </AppTableButton>
    );
};

const DateValidator = ({ date }) => {
    const isValid = date !== '0000-00-00';

    if (isValid) {
        return <span style={{ padding: '5px' }}>{date}</span>;
    }

    return (
        <Tooltip title={'Denna tid behöver uppdateras'} placement="top" arrow>
            <span style={{ border: 'solid 1px var(--red)', padding: '5px', borderRadius: '4px' }}>{date}</span>
        </Tooltip>
    );
}

export default class ScheduleDeviationTable extends DataList {

    constructor(params) {
        super(params);

        // Change names of columns accordingly to what to display
        this.columns = [
            {
                name: 'Datum',
                selector: 'date',
                sortable: true,
                cell: (row) => (<DateValidator date={row.date} />),
                grow: 1,
            },
            {
                name: 'Tillgäng tid',
                selector: 'timeAvailable',
                sortable: true,
                cell: (row) => row.timeAvailable + ' min',
                grow: 1,
            },
            {
                name: 'Beskrivning',
                selector: 'note',
                grow: 2,
            },
            {
                name: '',
                selector: '',
                right: true,
                cell: (row) => <EditButton row={row} onChange={this.refetch} />,
                grow: 1,
            },
        ];

        this.options = {
            initialPagination: {
                orderBy: 'date',
            },
        };

        this.baseFilters.push({
            field: 'storeId',
            operator: '=',
            intValue: params.storeId,
        });

        // Change query accordingly to where we fetch the orders
        this.query = GET_SCHEDULE_DEVIATIONS;
        this.queryKey = 'scheduleDeviations';
    }
    subHeaderComponent() {
        return (
            <>
                <CreateScheduleDeviationButton onChange={this.refetch} />
            </>
        );
    }
}
