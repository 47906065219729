import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import { Box } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import React from 'react';
import { useForm } from 'react-hook-form';
import {
    Mutation,
    MutationSendCustomerSmsArgs,
    Query,
    QuerySmsRecordsArgs,
    ServiceOrder,
    useGetCustomerByIdQuery,
    useGetSmsTemplateLazyQuery,
    useGetSmsTemplatesQuery,
} from 'src/generated/graphql';
import LoaderCircular, { LoaderWrapper } from 'src/modules/Common/Common/LoaderCircular';
import LoaderCircularDiscrete from 'src/modules/Common/Common/LoaderCircularDiscrete';
import { useInterval } from 'src/modules/Common/custom-hooks/useInterval';
import useIsMounted from 'src/modules/Common/custom-hooks/useIsMounted';
import { useQueryCache } from 'src/modules/Common/custom-hooks/useQueryCache';
import AlignItemsList from 'src/modules/ServiceOrder/components/Dialogs/ServiceOrderDialog/SmsTab/SmsList';
import SmsSendMessage from 'src/modules/ServiceOrder/components/Dialogs/ServiceOrderDialog/SmsTab/SmsSendMessage';
import { SEND_CUSTOMER_SMS, SMS_RECORDS } from 'src/modules/ServiceOrder/graphql/SmsRecordGql';

import { formatDate } from '../../../../../Common/helpers/dates';

interface Props {
    serviceOrder: ServiceOrder
}

type MutationArgs = Partial<MutationSendCustomerSmsArgs>;

export default function SmsTab({ serviceOrder }: Props) {
    const mounted = useIsMounted();

    // @todo Here we fetch the CUSTOMER by id, may need to handle Company some way too.
    const { data: customerData } = useGetCustomerByIdQuery({
        variables: { id: serviceOrder.customerId || 0 },
    });

    // Query to get records
    const { data, refetch } = useQuery<Query, QuerySmsRecordsArgs>(SMS_RECORDS, {
        variables: { serviceOrderId: serviceOrder.id },
        nextFetchPolicy: 'network-only',
    });

    // Send new sms
    const [postNewMessage, { loading: sending }] = useMutation<Mutation, MutationArgs>(SEND_CUSTOMER_SMS, {
        variables: { serviceOrderId: serviceOrder.id },
    });

    // Replace query result with
    const { replace } = useQueryCache(SMS_RECORDS, {
        variables: { serviceOrderId: serviceOrder.id },
    });

    // Function to refetch only if still mounted
    const safeRefetch = () => mounted && refetch().then();

    // Refetch whenever there are pending texts

    let intervals = 0;
    const refreshAfterIntervals = 10;

    useInterval(() => {
        // It's a bad hack but since react-tabs seems not to work well according to existing comments,
        // this at least prevents sms spam leading to backend failures
        // now we won't fetch new sms'es if sms tab is not open
        if (intervals < refreshAfterIntervals) {
            intervals++;
        } else if (
            intervals === refreshAfterIntervals &&
            document.querySelector('.dialog2-selected-tab[data-tab-text="Sms"]')
        ) {
            intervals = 0;
            safeRefetch();
        }
        // Disabled so we always periodically update.
        // Because it would not always update on status changes.
        /*
        const items = data?.smsRecords.items || [];

        const finalStatuses = [
            SmsRecordStatus.Success, // Deprecated
            SmsRecordStatus.Failed,
            SmsRecordStatus.Error,
            SmsRecordStatus.Skipped,
            SmsRecordStatus.Delivered,
        ]

        if (items.find((item) => !finalStatuses.includes(item.status))) {
            safeRefetch();
        }
        */
    }, 250);

    // Reload on status changes
    // Disabled for now because it does not always work.
    // We use periodic updates instead.
    /*
    useServiceOrderSubscriptionEffect(serviceOrder.id, () => {
        setTimeout(safeRefetch, 1000);
        setTimeout(safeRefetch, 5000);
    });
    */
    const methods = useForm();

    const [onGetTemplate] = useGetSmsTemplateLazyQuery({
        onCompleted: (data) => {
            methods.setValue('message', data.template?.message || '');
        }
    })

    const { data: templatesData, loading } = useGetSmsTemplatesQuery()

    const templates = templatesData?.templates || [];

    const onClick = (template: string) => {
        onGetTemplate({
            variables: {
                input: {
                    serviceOrderId: serviceOrder.id,
                    templateKey: template,
                    date: formatDate(serviceOrder.serviceDate),
                    externalKey: serviceOrder.externalKey || '',
                }
            }
        });
    }

    const onNewMessage = async (message: string) => {
        // Post the message
        const { data } = await postNewMessage({ variables: { message } });

        if (data) {
            // Replace the cache result with the new data
            await replace({ smsRecords: data.sendCustomerSms });
        }

        // Fetching to get new message
        setTimeout(safeRefetch, 1000);
        setTimeout(safeRefetch, 5000);
    };

    if (!data || loading) {
        return <LoaderWrapper><LoaderCircular size={30} /></LoaderWrapper>;
    }

    const items = data.smsRecords.items;

    return (
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
            <Box style={{ display: 'flex', flexDirection: 'column', position: 'absolute', gap: '10px' }}>
                {templates.map(({ template, label }, index) => (
                    <Button
                        key={index}
                        onClick={() => onClick(template || '')}
                        variant='contained'
                        style={{ backgroundColor: '#000', color: '#fff' }}
                    >
                        {label}
                    </Button>
                ))}
            </Box>
            <Card className="service-order-sms-tab">
                {sending && <LoaderCircularDiscrete />}
                <CardContent>
                    <AlignItemsList
                        items={items}
                    />
                    {customerData?.customer?.consentSms ? (
                        <SmsSendMessage
                            onNewMessage={onNewMessage}
                            serviceOrder={serviceOrder}
                            methods={methods}
                        />
                    ) : (
                        <div className="sms-not-allowed">
                            Kund har ej accepterat meddelanden via sms.
                        </div>
                    )}
                </CardContent>
            </Card>
        </Box>
    );
}
