import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef } from 'react';
import { SmsRecord } from 'src/generated/graphql';
import { formatDateTime } from 'src/modules/Common/helpers/dates';
import { SmsRecordStatusIcon } from 'src/modules/ServiceOrder/components/Dialogs/ServiceOrderDialog/SmsTab/SmsRecordStatusIcon';
import { notificationTypeText } from 'src/modules/ServiceOrder/utils/smsHelper';

const useStyles = makeStyles(() => ({
    root: {
        height: 400,
        overflowY: 'scroll',
    },
    list: {
        paddingTop: 0,
    },
    time: {
        display: 'inline-block',
        width: 125,
    },
    icon: {
        float: 'right',
        height: 16,
    },
}));

interface Props {
    items: SmsRecord[]
}

export default function AlignItemsList(props: Props) {
    const classes = useStyles();

    const scrollElement = useRef<HTMLElement | any>(null);
    const itemCount = props.items?.length || 0;

    useEffect(() => {
        // scroll to bottom
        const element = scrollElement.current;

        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }, [itemCount, scrollElement]);

    return (
        <Card variant='outlined' className={classes.root} ref={scrollElement}>
            <List className={classes.list}>
                {props.items.map((item, i) => (
                    <React.Fragment key={i}>
                        {i !== 0 && <Divider component="li" variant="middle" />}
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={
                                    <>
                                        <span className={classes.time}>{formatDateTime(item.createdAt)}</span>
                                        {notificationTypeText(item.type)}
                                        <div className={classes.icon}>
                                            <SmsRecordStatusIcon status={item.status} />
                                        </div>
                                    </>
                                }
                                secondary={
                                    <span className="sms-message-body">
                                        {item.message}
                                    </span>
                                }
                            />
                        </ListItem>
                    </React.Fragment>
                ))}
            </List>
        </Card>
    );
}
