import React, { createContext, useContext, useEffect, useState } from 'react';

import { SubStore, SubStoreStatus } from '../../../generated/graphql';
import { useStore } from './store-context';

export type SubStoreType = Pick<SubStore, 'id' | 'name' | 'adminUserId' | 'status' | 'createdAt' | 'updatedAt' | 'store'>;

export type SetSubStoreIdFunctionType = (id: number | undefined) => void;

export type SubStoreContextType = {
    selectedSubStoreId: number | undefined;
    setSelectedSubStoreId: SetSubStoreIdFunctionType;
    selectedSubStore: SubStoreType | undefined;
    subStores: SubStoreType[];
    setSubStores: (subStores: SubStoreType[]) => void;
}

export const SubStoreContext = createContext<SubStoreContextType>({
    selectedSubStoreId: undefined,
    setSelectedSubStoreId: () => { },
    selectedSubStore: undefined,
    subStores: [],
    setSubStores: () => { },
});

type SubStoreContextProviderType = {
    children: any[];
}

export const SubStoreContextProvider = (props: SubStoreContextProviderType) => {
    const { children } = props;

    const [selectedSubStoreId, setSelectedSubStoreId] = useState<number | undefined>(() => {
        const selectedJson = localStorage.getItem('selectedSubStore');
        return selectedJson ? JSON.parse(selectedJson) : undefined;
    });

    const [storeId] = useStore();

    const [subStores, setSubStores] = useState<SubStoreType[] | undefined>();

    useEffect(() => {
        selectedSubStoreId
            ? localStorage.setItem('selectedSubStore', JSON.stringify(selectedSubStoreId))
            : localStorage.removeItem('selectedSubStore');

        if (typeof subStores !== "undefined") {
            const foundSubStore = findSelectedSubStore(selectedSubStoreId, subStores, storeId);
            setSelectedSubStoreId(foundSubStore?.id);
        }
    }, [selectedSubStoreId, subStores, storeId]);

    const handleSubStoreSelect: SetSubStoreIdFunctionType = (id) =>
        setSelectedSubStoreId((prevState) => {
            if (id === undefined) return undefined;

            return prevState === id ? undefined : id;
        });

    return (
        <SubStoreContext.Provider value={{
            selectedSubStoreId: selectedSubStoreId,
            setSelectedSubStoreId: handleSubStoreSelect,
            selectedSubStore: findSelectedSubStore(selectedSubStoreId, subStores ?? [], storeId),
            subStores: subStores ?? [],
            setSubStores,
        }}>
            {children}
        </SubStoreContext.Provider >
    );
};

export function useSubStore(): SubStoreContextType {
    return useContext(SubStoreContext);
}

function findSelectedSubStore(selectedSubStoreId: number | undefined, subStores: SubStoreType[], storeId: number | undefined) {
    return selectedSubStoreId
        ? subStores.find((subStore) => subStore.id === selectedSubStoreId
            && subStore.status === SubStoreStatus.Active
            && subStore.store.id === storeId)
        : undefined;
}
