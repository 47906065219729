import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint';
import Typography from '@material-ui/core/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { StoreMetadataQuery, useStoreMetadataQuery, useUpdateStoreMetadataMutation } from '../../generated/graphql';
import { Qztray } from '../../services/Qztray/Qztray';
import LoaderCircular from '../Common/Common/LoaderCircular';
import { useStore } from '../Common/context/store-context';
import AppButton from '../Common/Form/AppButton';
import AppTextInput from '../Common/Form/AppTextInput';


const StoreMetadataForm = () => {
    const { register, handleSubmit, reset, getValues } = useForm({
        defaultValues: {
            printerOffsetLeft: 0,
        },
    });

    const [ storeId ] = useStore();
    const { enqueueSnackbar } = useSnackbar();

    const { data, loading } = useStoreMetadataQuery({ variables: { storeId } });
    const [ onUpdateStoreMetadata, { loading: updating } ] = useUpdateStoreMetadataMutation();

    useEffect(() => {
        reset({ printerOffsetLeft: data?.storeMetadata?.printerOffsetLeft || 0 });
    }, [data, reset])

    if (!data || loading) return <LoaderCircular visible={true} size={10} />;

    const transformFormValues = (formValues: { printerOffsetLeft: number }) => {
        return {
            storeId: storeId,
            printerOffsetLeft: Number(formValues.printerOffsetLeft) || 0,
        };
    };

    const onSubmit = async (formValues: { printerOffsetLeft: number }) => {
        await onUpdateStoreMetadata({
            variables: { storeId, formValues: transformFormValues(formValues) },
            refetchQueries: ['StoreMetadata']
        });

        enqueueSnackbar('Inställningarna har uppdaterats!', { variant: 'success' });
    };

    return (
        <div className="store-metadata-form">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Typography color='textSecondary'>
                    Skrivarinställningar
                </Typography>
                <div className='store-metadata-form-fields'>
                    <AppTextInput
                        id='printerOffsetLeft'
                        label='Justering från vänster'
                        name='printerOffsetLeft'
                        type='number'
                        inputRef={register}
                    />
                    <AppButton
                        size='small'
                        color='primary'
                        type='button'
                        icon={faPrint}
                        onClick={() => testPrint(getValues().printerOffsetLeft)}
                    >
                        Test print
                    </AppButton>
                </div>
                <AppButton
                    disabled={updating}
                    type='submit'
                    icon={faCheckCircle}
                >
                    Spara
                </AppButton>
            </form>
        </div>
    );
};

export default StoreMetadataForm;

function testPrint(x: number) {
    Qztray.printRaw(`^XA
^CI27

^FX -- Set the font size (CFA,20  for mono spaced) --
^CFC,15

^FO${x},30^FDThis text^FS
^FO${x},50^FDshould align^FS
^FO${x},70^FDwith the left side^FS

^FX -- Order number --
^CFC,40
^FO${x},130^FDS123456^FS

^FX -- Barcode --
^BY3,2,50
^FO${x},170
^BC,80,N,N,N,N
^FDS123456^FS

^XZ`);
}
