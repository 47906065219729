import { green, red, yellow } from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowForward from '@material-ui/icons/ArrowForward';
import CallMissedOutgoing from '@material-ui/icons/CallMissedOutgoing';
import Check from '@material-ui/icons/Check';
import Error from '@material-ui/icons/Error';
import Info from '@material-ui/icons/Info';
import Schedule from '@material-ui/icons/Schedule';
import Warning from '@material-ui/icons/Warning';
import React from 'react';
import { SmsRecordStatus } from 'src/generated/graphql';

export function SmsRecordStatusIcon(props: { status: SmsRecordStatus }) {

    switch (props.status) {
        case SmsRecordStatus.Pending:
            return <Tooltip title="Pending"><Schedule style={{ color: yellow[500] }} /></Tooltip>;
        case SmsRecordStatus.Success:
            return <Tooltip title="Success"><Check style={{ color: green[500] }} /></Tooltip>;
        case SmsRecordStatus.Test:
            return <Tooltip title="Test"><Info style={{ color: yellow[500] }} /></Tooltip>;
        case SmsRecordStatus.Skipped:
            return <Tooltip title="Skipped"><CallMissedOutgoing color="disabled" /></Tooltip>;
        case SmsRecordStatus.Error:
            return <Tooltip title="Error"><Error style={{ color: red[500] }} /></Tooltip>;
        case SmsRecordStatus.Queued:
            return <Tooltip title="Queued"><Schedule style={{ color: yellow[500] }} /></Tooltip>;
        case SmsRecordStatus.Accepted:
            return <Tooltip title="Accepted"><Schedule style={{ color: green[500] }} /></Tooltip>;
        case SmsRecordStatus.Sending:
            return <Tooltip title="Sending"><ArrowForward style={{ color: green[500] }} /></Tooltip>;
        case SmsRecordStatus.Sent:
            return <Tooltip title="Sent"><ArrowForward style={{ color: green[500] }} /></Tooltip>;
        case SmsRecordStatus.Failed:
            return <Tooltip title="Failed"><Warning style={{ color: red[500] }} /></Tooltip>;
        case SmsRecordStatus.Delivered:
            return <Tooltip title="Delivered"><Check style={{ color: green[500] }} /></Tooltip>;
    }

    return null;
}
