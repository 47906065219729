import gql from 'graphql-tag';

export const SMS_RECORDS = gql`
    query smsRecords($serviceOrderId: Float!) {
        smsRecords(serviceOrderId: $serviceOrderId) {
            items {
                createdAt
                type
                status
                message
            }
        }
    }
`;

export const SEND_CUSTOMER_SMS = gql`
    mutation sendCustomerSms($serviceOrderId: Float!, $message: String!) {
        sendCustomerSms(serviceOrderId: $serviceOrderId, message: $message) {
            items {
                createdAt
                type
                status
                message
            }
        }
    }
`;
